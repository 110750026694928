import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { useWeb3React } from '@web3-react/core';

import BTEIcon from '../../assets/images/betero-icon.svg';
import CashbackIcon from '../../assets/images/cashback-icon.svg';
import BurnIcon from '../../assets/images/burn-icon.svg';
import StakingRewardsIcon from '../../assets/images/staking-rewards-icon.svg';
import MarketingIcon from '../../assets/images/marketing-icon.svg';
import InfoIcon from '../../assets/images/info-icon.svg';
import ChevronIcon from '../../assets/images/chevron-white.svg';
import { PORTAL_DATA } from '../../services/config';
import KyberSwapWidget from '../BuyBTEPopup/KyberSwapWidget';
import { CONTRACT_BTE_GOVERNANCE } from '../../abis/constants';
import { useTokenPrice } from '../../hooks';
import { Contract } from 'ethers';
// import WalletPopup from '../WalletPopup';

export const NewFarmHeader = (props) => {
  const { account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const { locale } = useSelector(state => state.common);
  const { readRemoteFile } = usePapaParse();
  const [btePrice, setBtePrice] = useState(0);
  const [latestProposalTitle, setLatestProposalTitle] = useState('');
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [portalData, setPortalData] = useState({
    currentMonth: '',
    currentMonthUSD: '',
    currentMonthBTE: '',
    prevMonth: '',
    prevMonthUSD: '',
    prevMonthBTE: '',
  });
  // const [openWalletPopup, setOpenWalletPopup] = useState(false);

  const initProcess = useCallback(async () => {
    const bte_price = await getBTEPrice();
    setBtePrice(bte_price);
    if (!account || !provider) return;

    try {
      const governanceContract = new Contract(CONTRACT_BTE_GOVERNANCE.address, CONTRACT_BTE_GOVERNANCE.abi, provider.getSigner(account));
      const _currentProposal = await governanceContract.currentProposal();
      const proposalInfo = await governanceContract.proposals(_currentProposal);
      setLatestProposalTitle(proposalInfo.name);
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider])

  const getPortalData = useCallback(() => {
    readRemoteFile(PORTAL_DATA, {
      complete: (results) => {
        const _result = results.data;
        if (_result && _result.length > 13) {
          const currentMonth = _result[1][1];
          const currentMonthUSD = _result[2][1];
          const currentMonthBTE = _result[3][1];
          const prevMonth = _result[4][1];
          const prevMonthUSD = _result[5][1];
          const prevMonthBTE = _result[6][1];
          const cashbackBTE = _result[10][1];
          const stakingRewardsBTE = _result[11][1];
          const burnBTE = _result[12][1];
          const marketingBTE = _result[13][1];
          setPortalData({
            currentMonth,
            currentMonthUSD,
            currentMonthBTE,
            prevMonth,
            prevMonthUSD,
            prevMonthBTE,
            cashbackBTE,
            stakingRewardsBTE,
            burnBTE,
            marketingBTE,
          });
        }
      },
    })
  }, [readRemoteFile]);

  useEffect(() => {
    initProcess();
    getPortalData();
  }, [initProcess, getPortalData])

  useEffect(() => {
    const interval = setInterval(() => {
      initProcess();
    }, 10000);

    return () => clearInterval(interval);
  }, [initProcess])

  const handleClick = () => {
    setOpenBuyPopup(true);
  }

  return (
    <>
      <div>
        <div className='pt-4 flex justify-between items-end'>
          <div>
            <h3 className='text-19 font-bold'>{locale['betero_portal']}</h3>
            <p className='text-15 pt-1'>{locale['earn_passive_income_bte']}</p>
          </div>
          <div
            className='h-50px p-1 rounded-full border border-green-primary bg-green-overlay cursor-pointer flex items-center'
            onClick={() => handleClick()}
          >
            <img src={BTEIcon} alt="" className='w-38px h-38px' />
            <span className='text-14 px-5 font-montserrat'>
              {locale['buy']} {' '}
              <span className='font-bold'>BTE</span>
            </span>
          </div>
        </div>
        <div className='flex items-center gap-2  pt-5'>
          <div className='flex-1 grid grid-cols-1 md:grid-cols-3 gap-2'>
            <div className='px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl'>
              <p className='text-15 text-gray-light font-medium'>{locale['bte_price']}</p>
              <div className='flex'>
                <h2 className='text-30 mr-2'>${btePrice?.toFixed(4)}</h2>
                {/* <div className='ml-2 mt-2'>
                    <div className='flex items-center rounded-full bg-green-overlay px-1 py-0.5'>
                        <img src={ArrowUpIcon} alt="" className='w-1.5 h-1 mr-1' />
                        <span className='text-9 font-bold text-green-primary'>{'2.6'}%</span>
                    </div>
                </div> */}
              </div>
            </div>
            {!expanded && (
              <div className='hidden md:block px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl'>
                <p className='text-15 text-gray-light font-medium'>{locale['betero_platform_profits']}</p>
                <div className='flex items-center mt-2.5'>
                  <img src={BTEIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                  <div className='ml-2'>
                    <h5 className='text-12 md:text-19 font-medium leading-none'>
                      {portalData.currentMonthBTE} BTE
                    </h5>
                    <p className='text-10 md:text-12 text-gray-light'>
                      {portalData.currentMonthUSD}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className={`${expanded && 'col-span-2'} hidden md:flex`}>
              {!expanded ? (
                <div className={`flex-1 flex flex-col justify-between px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl`}>
                  <p className='text-15 text-gray-light font-medium'>{locale['betero_dao_governance']}</p>
                  <a href='/vote'>
                    <p className='text-15 text-green-primary font-medium'>{`> ${locale['vote_now']}`}</p>
                  </a>
                </div>
              ) : (
                <div className={`flex-1 flex justify-between items-center px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl`}>
                  <div>
                    <p className='text-15 text-gray-light font-medium'>{locale['betero_dao_governance']} - {locale['latest_proposal']}</p>
                    <p className='text-18 text-white font-medium w-10/12'>{latestProposalTitle}</p>
                  </div>
                  <a href='/vote'>
                    <p className='text-15 text-green-primary font-medium'>{`> ${locale['vote_now']}`}</p>
                  </a>
                </div>
              )}
            </div>
            <div className='md:hidden'>
              <div className={`flex flex-col justify-between px-4 md:px-6 py-4 md:py-5 bg-secondary gap-2.5 rounded-xl`}>
                <p className='text-15 text-gray-light font-medium'>{locale['betero_dao_governance']} - {locale['latest_proposal']}</p>
                <p className='text-15 text-white font-medium'>{latestProposalTitle}</p>
                <a href='/vote'>
                  <p className='text-15 text-green-primary font-medium'>{`> ${locale['vote_now']}`}</p>
                </a>
              </div>
            </div>
          </div>
          {!expanded && (
            <div
              className='hidden md:flex items-center justify-center w-8 h-8 border border-green-primary rounded-full bg-green-overlay cursor-pointer'
              onClick={() => setExpanded(true)}
            >
              <img src={ChevronIcon} alt="" className='w-6 h-6' />
            </div>
          )}
        </div>
        {!expanded ? (
          <div className='flex items-center justify-between md:hidden mt-2 px-4 py-4 bg-secondary rounded-xl'>
            <div>
              <p className='text-15 text-gray-light font-medium'>{locale['betero_platform_profits']}</p>
              <p className='mt-2 text-15 text-gray-light font-medium'>{portalData.currentMonth}</p>
              <div className='flex items-center mt-2'>
                <img src={BTEIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                <div className='ml-2'>
                  <h5 className='text-19 font-medium leading-none'>
                    {portalData.currentMonthBTE} BTE
                  </h5>
                  <p className='text-12 text-gray-light'>
                    {portalData.currentMonthUSD}
                  </p>
                </div>
              </div>
            </div>
            <div
              className='flex items-center justify-center w-8 h-8 border border-green-primary rounded-full bg-green-overlay cursor-pointer'
              onClick={() => setExpanded(true)}
            >
              <img src={ChevronIcon} alt="" className='w-6 h-6' />
            </div>
          </div>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-3 mt-2 px-4 md:px-0 py-0 md:py-5 divide-divider divide-y md:divide-y-0 divide-x-0 md:divide-x bg-secondary rounded-xl'>
            <div className='px-0 md:px-6 py-4 md:py-0'>
              <p className='text-15 text-gray-light font-medium'>{locale['betero_platform_profits']}</p>
              <p className='mt-2 text-15 text-gray-light font-medium'>{portalData.currentMonth}</p>
              <div className='flex items-center mt-2'>
                <img src={BTEIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                <div className='ml-2'>
                  <h5 className='text-19 font-medium leading-none'>
                    {portalData.currentMonthBTE} BTE
                  </h5>
                  <p className='text-12 text-gray-light'>
                    {portalData.currentMonthUSD}
                  </p>
                </div>
              </div>
              <p className='mt-2 text-15 text-gray-light font-medium'>{portalData.prevMonth}</p>
              <div className='flex items-center mt-2'>
                <img src={BTEIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                <div className='ml-2'>
                  <h5 className='text-19 font-medium leading-none'>
                    {portalData.prevMonthBTE} BTE
                  </h5>
                  <p className='text-12 text-gray-light'>
                    {portalData.prevMonthUSD}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-span-2 px-0 md:px-6 py-4 md:py-0'>
              <div className='flex items-center justify-between md:justify-start'>
                <p className='text-15 text-gray-light font-medium'>{locale['profit_redistribution']} - {portalData.currentMonth}</p>
                <a href='https://youtu.be/jOmmfLZJb3U' target='_blank' rel="noreferrer">
                  <img src={InfoIcon} alt="" className='ml-2 w-4 md:w-4 h-4 md:h-4 cursor-pointer' />
                </a>
              </div>
              <div className='grid grid:cols-1 md:grid-cols-2 mt-4 gap-y-5'>
                <div className='flex items-center'>
                  <img src={CashbackIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                  <div className='ml-2'>
                    <p className='text-15 text-gray-light'>
                      {locale['cashback']}
                    </p>
                    <h5 className='text-15 font-medium leading-none'>
                      {portalData.cashbackBTE} BTE
                    </h5>
                  </div>
                </div>
                <div className='flex items-center'>
                  <img src={BurnIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                  <div className='ml-2'>
                    <p className='text-15 text-gray-light'>
                      {locale['burn']}
                    </p>
                    <h5 className='text-15 font-medium leading-none'>
                      {portalData.burnBTE} BTE
                    </h5>
                  </div>
                </div>
                <div className='flex items-center'>
                  <img src={StakingRewardsIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                  <div className='ml-2'>
                    <p className='text-15 text-gray-light'>
                      {locale['staking_rewards']}
                    </p>
                    <h5 className='text-15 font-medium leading-none'>
                      {portalData.stakingRewardsBTE} BTE
                    </h5>
                  </div>
                </div>
                <div className='flex items-center'>
                  <img src={MarketingIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
                  <div className='ml-2'>
                    <p className='text-15 text-gray-light'>
                      {locale['marketing']}
                    </p>
                    <h5 className='text-15 font-medium leading-none'>
                      {portalData.marketingBTE} BTE
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
      {openBuyPopup && (
        // <BuyBTEPopup
        //   setOpenPopup={setOpenBuyPopup}
        // />
        <KyberSwapWidget openBuyPopup={openBuyPopup} setOpenPopup={setOpenBuyPopup} />
      )}

    </>
  )
}
