import React from 'react';
import { Routes as Router, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Vote from '../pages/Vote';
import Migration from '../pages/Migration';
import { NotFound } from '../pages/404';
// import ScrollIntoView from '../components/ScrollIntoView';

function Routes() {
    return (
        // <ScrollIntoView>
        <Router>
            <Route path={'/'} element={<Home />} />
            <Route path={'/vote'} element={<Vote />} />
            <Route path={'/buybte'} element={<Home />} />
            <Route path={'/migration'} element={<Migration />} />
            <Route path='*' element={<NotFound />} />
        </Router>
    )
}

export default Routes;
