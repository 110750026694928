import React from 'react';
import ReactGA from 'react-ga';
import Routes from './routes';
import './App.css';
import './assets/css/global.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { TRACKING_ID } from './services/config';

ReactGA.initialize(TRACKING_ID);
toast.configure();

function App() {
  return (
    <>
      <Header />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
