import React, { useEffect, useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import BTEIcon from '../../assets/images/betero-icon.svg';
import GoldenBadge from '../../assets/images/gold.svg';
import SilverBadge from '../../assets/images/silver.svg';
import BronzeBadge from '../../assets/images/bronze.svg';
import WoodenBadge from '../../assets/images/wooden.svg';
import { DECIMALS, BSC_FARM_CONTRCTS, BSCTEST_FARM_CONTRACTS, ETH_FARM_CONTRCTS, CRO_FARM_CONTRCTS } from '../../abis/constants';
import { parseBNumber, readableNumber } from '../../services/utils';
import KyberSwapWidget from '../BuyBTEPopup/KyberSwapWidget';
import { useSelector } from 'react-redux';
import { Contract } from 'ethers';
import { useTokenPrice } from '../../hooks';

const farmList = {
  56: BSC_FARM_CONTRCTS,
  97: BSCTEST_FARM_CONTRACTS,
  1: ETH_FARM_CONTRCTS,
  25: CRO_FARM_CONTRCTS
}

export const FarmHeader = (props) => {
  const { chainId, account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const { locale } = useSelector(state => state.common);
  const [btePrice, setBtePrice] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [openBuyPopup, setOpenBuyPopup] = useState(false);

  const initProcess = useCallback(async () => {
    const bte_price = await getBTEPrice();
    setBtePrice(bte_price);
    if (!account || !provider || chainId !== 56) return;

    const signer = provider.getSigner(account);

    let total_earned = 0; // in BTE
    let total_deposit = 0; // in USD
    for (const contract of farmList[chainId]) {
      const farmContract = new Contract(contract.address, contract.abi, signer);

      const pendingReward = await farmContract.pendingReward(account);
      const earnedBTE = parseBNumber(pendingReward, DECIMALS);
      total_earned += earnedBTE;

      const userInfo = await farmContract.userInfo(account);
      const userStakedToken = parseBNumber(userInfo?.amount || 0, DECIMALS);

      if (contract.type === 'SINGLE') {
        total_deposit += userStakedToken;
      } else {
        const tokenContract = new Contract(contract.tokenAddress, contract.tokenAbi, signer);
        const totalSupply = await tokenContract.totalSupply(); // total OP
        const totalLp = parseBNumber(totalSupply, DECIMALS);
        if (totalLp <= 0) return;

        // get reserves on the token
        const reserves = await tokenContract?.getReserves();
        let totalBTE = 0;

        if (contract.secondToken === 'BNB') {
          totalBTE = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
        } else if (contract.secondToken === 'BUSD') {
          totalBTE = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
        } else if (contract.secondToken === 'ETH') {
          totalBTE = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
        } else if (contract.secondToken === 'CRO') {
          totalBTE = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
        }

        total_deposit += userStakedToken * totalBTE / totalLp;
      }
    }
    setTotalEarnings(total_earned);
    setTotalDeposit(total_deposit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    initProcess();
  }, [initProcess])

  useEffect(() => {
    const interval = setInterval(() => {
      initProcess();
    }, 10000);

    return () => clearInterval(interval);
  }, [initProcess])

  const getBadge = (amount) => {
    if (amount < 30000) return WoodenBadge;
    if (amount < 150000) return BronzeBadge;
    if (amount < 500000) return SilverBadge;
    return GoldenBadge;
  }

  const handleClick = () => {
    setOpenBuyPopup(true);
  }

  return (
    <>
      <div>
        <div className='pt-4 flex justify-between items-end'>
          <div>
            <h3 className='text-19 font-bold'>{locale['yield_farming']}</h3>
            <p className='text-15 pt-1'>{locale['state_bte_tokens_to_earn']}</p>
          </div>
          <div
            className='h-50px p-1 rounded-full border border-green-primary bg-green-overlay cursor-pointer flex items-center'
            onClick={() => handleClick()}
          >
            <img src={BTEIcon} alt="" className='w-38px h-38px' />
            <span className='text-14 px-5 font-montserrat'>
              {locale['buy']} {' '}
              <span className='font-bold'>BTE</span>
            </span>
          </div>
        </div>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2 pt-5'>
          <div className='px-4 md:px-6 py-3 md:py-5 bg-secondary rounded-xl'>
            <p className='text-15 text-gray-light font-medium'>{locale['bte_price']}</p>
            <div className='flex'>
              <h2 className='text-30 mr-2'>${btePrice.toFixed(4)}</h2>
              {/* <div className='ml-2 mt-2'>
                  <div className='flex items-center rounded-full bg-green-overlay px-1 py-0.5'>
                      <img src={ArrowUpIcon} alt="" className='w-1.5 h-1 mr-1' />
                      <span className='text-9 font-bold text-green-primary'>{'2.6'}%</span>
                  </div>
              </div> */}
            </div>
          </div>
          <div className='px-4 md:px-6 py-3 md:py-5 bg-secondary rounded-xl'>
            <p className='text-15 text-gray-light font-medium'>{locale['total_earnings']}</p>
            <div className='flex items-center mt-2.5'>
              <img src={BTEIcon} alt="" className='w-6 md:w-9 h-6 md:h-9' />
              <div className='ml-2'>
                <h5 className='text-12 md:text-19 font-medium leading-none'>
                  {readableNumber(totalEarnings.toFixed(2))} BTE
                </h5>
                <p className='text-10 md:text-12 text-gray-light'>
                  ${readableNumber((totalEarnings * btePrice).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
          <div className='px-4 md:px-6 py-3 md:py-5 bg-secondary rounded-xl'>
            <p className='text-15 text-gray-light font-medium'>{locale['total_holdings']}</p>
            <div className='flex items-center mt-2.5'>
              <div className='w-9 h-9 rounded-full bg-primary flex items-center justify-center'>
                <img src={getBadge(totalDeposit)} alt="" className='w-3 h-5 md:h-9' />
              </div>
              <div className='ml-2'>
                <h5 className='text-12 md:text-19 font-medium leading-none'>
                  {readableNumber(totalDeposit.toFixed(2))} BTE
                </h5>
                <p className='text-10 md:text-12 text-gray-light'>
                  ${readableNumber((totalDeposit * btePrice).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openBuyPopup && (
        // <BuyBTEPopup setOpenPopup={setOpenBuyPopup} />
        <KyberSwapWidget />
      )}
    </>
  )
}
