// import BNB_BUSD_ABI from './BNB_BUSD_ABI.json';
// import BNB_BTE_ABI from './BNB_BTE_ABI.json';
import BTE_SINGLE_FARM_ABI from './BTE_SINGLE_FARM_ABI.json';
import BTE_SINGLE_TOKEN_ABI from './BTE_SINGLE_TOKEN_ABI.json';
import BTE_LP_FARM_ABI from './BTE_LP_FARM_ABI.json';
import BTE_LP_TOKEN_ABI from './BTE_LP_TOKEN_ABI.json';
import BTE_GOVERNANCE_ABI from './BTE_GOVERNANCE_ABI.json';
import BTE_CASHBACK_ABI from './BTE_CASHBACK_ABI.json';
import BUY_BTE_ABI from './BUY_BTE_ABI.json';
import BTE_MIGRATION_CONTRACT_ABI from './BTE_MIGRATION_CONTRACT_ABI.json'
import NEW_BTE_TOKEN_ABI from './NEW_BTE_TOKEN_ABI.json'
import BTE_CRO_ABI from './BTE_CRO_ABI.json'
import BTE_ETH_ABI from './BTE_ETH_ABI.json'

export const DEFAULT_NET = 56;
export const SUPPORTED_NET = [56, 1, 25];
export const COMPOUNDING_PERIOD = 365;
export const DECIMALS = 18;
export const MONTH_SEC = 2629800;

export const RPC_URLS = {
    1: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    25: ['https://evm-cronos.crypto.org', 'https://evm.cronos.org'],
    56: ['https://bsc-dataseed.binance.org/', 'https://bsc-dataseed3.binance.org'],
}

const ethNet = [{
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
}];
const bscNet = [{
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
}];
const bscTestNet = [{
    chainId: '0x61',
    chainName: 'BSC Testnet',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
}];
const cronosNet = [{
    chainId: '0x19',
    chainName: 'Cronos',
    nativeCurrency: {
        name: 'CRO',
        symbol: 'CRO',
        decimals: 18
    },
    rpcUrls: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer/'],
}];

export const NETWORK_INFO = {
    56: bscNet,
    25: cronosNet,
    1: ethNet,
    97: bscTestNet
};

export const CONTRACT_BNB_BUSD = {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    abi: BTE_LP_TOKEN_ABI
}

// todo
export const CONTRACT_BNB_BTE = {
    address: '0x219A2E0D19ebe7eeBaba752E33FA7F3184b6326f',
    abi: BTE_LP_TOKEN_ABI
}

export const CONTRACT_ETH_BTE = {
    address: '0x130aE7A9Ed20cED337636B57B9562769D9A95EBb',
    abi: BTE_LP_TOKEN_ABI
}

export const CONTRACT_CRO_BTE = {
    address: '0x8eFaf9F56c7AaEA39823F3c3527b345CB2AFE658',
    abi: BTE_LP_TOKEN_ABI
}

export const CONTRACT_ETH_USDC = {
    address: '0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc',
    abi: BTE_LP_TOKEN_ABI
}

export const CONTRACT_CRO_USDC = {
    address: '0xe61Db569E231B3f5530168Aa2C9D50246525b6d6',
    abi: BTE_LP_TOKEN_ABI
}

export const CONTRACT_BTE_GOVERNANCE = {
    address: '0xbf9481b15D0db6Dc944Cac996200dc8a9a82a7d3',
    abi: BTE_GOVERNANCE_ABI
}


export const CONTRACT_BTE_CASHBACK = {
    address: '0x8Da1ce4491C343c84a0C034d844985186bC3910B',
    abi: BTE_CASHBACK_ABI
}

export const BSC_FARM_CONTRCTS = [
    // {
    //     name: 'BTE-BNB',
    //     eventLabel: '3months_BSC',
    //     vesting: 1,
    //     type: 'MULTIPLE',
    //     address: '0xcCD9af13Aa5132e36dBE524DE6Acc26405209Da2',
    //     abi: BTE_LP_FARM_ABI,
    //     secondToken: 'BNB',
    //     tokenAddress: '0x225AbA62425a28F5e19Eb9E2DF2A53E161061e29',
    //     rewardTokenAddress: '0xea89199344a492853502a7A699Cc4230854451B8',
    //     tokenAbi: BTE_LP_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0xcCD9af13Aa5132e36dBE524DE6Acc26405209Da2#readContract',
    //     buyTokenLink: "https://pancakeswap.finance/add/BNB/0xea89199344a492853502a7A699Cc4230854451B8"
    // },
    {
        chainId: 56,
        name: 'BTE',
        eventLabel: '1months_BSC',
        vesting: 1,
        type: 'SINGLE',
        address: '0x56a46d78066e716524848bDa1E369c36883a439a',
        abi: BTE_SINGLE_FARM_ABI,
        tokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        rewardTokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        tokenAbi: NEW_BTE_TOKEN_ABI,
        contractLink: 'https://bscscan.com/address/0x56a46d78066e716524848bDa1E369c36883a439a#code',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7c33D9c9685408645486497c708ab491402e0886'
    },
    {
        chainId: 56,
        name: 'BTE',
        eventLabel: '3months_BSC',
        vesting: 3,
        type: 'SINGLE',
        address: '0x25e78a118299409bB8d9cDA822a038c843D8ABc7',
        abi: BTE_SINGLE_FARM_ABI,
        tokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        rewardTokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        tokenAbi: NEW_BTE_TOKEN_ABI,
        contractLink: 'https://bscscan.com/address/0x25e78a118299409bB8d9cDA822a038c843D8ABc7#code',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7c33D9c9685408645486497c708ab491402e0886'
    },
    {
        chainId: 56,
        name: 'BTE',
        eventLabel: '12months_BSC',
        vesting: 12,
        type: 'SINGLE',
        address: '0xc06934Cc0601F8d8bE7d3Ca518a3Fdba639E41Dc',
        abi: BTE_SINGLE_FARM_ABI,
        tokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        rewardTokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        tokenAbi: NEW_BTE_TOKEN_ABI,
        contractLink: 'https://bscscan.com/address/0xc06934Cc0601F8d8bE7d3Ca518a3Fdba639E41Dc#code',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7c33D9c9685408645486497c708ab491402e0886'
    },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '1months_BSC',
    //     vesting: 1,
    //     type: 'SINGLE',
    //     address: '0x9FaD9639efAe86693209a5F5028Bb02a2251186d',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0x9FaD9639efAe86693209a5F5028Bb02a2251186d#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '2months_BSC',
    //     vesting: 2,
    //     type: 'SINGLE',
    //     address: '0x8809069f2902d1776f6874Bf5775dC236dFaa8b3',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0x8809069f2902d1776f6874Bf5775dC236dFaa8b3#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '3months_BSC',
    //     vesting: 3,
    //     type: 'SINGLE',
    //     address: '0x10fE3E0261f67f3A000cae2E7D4Ad1b8df86Dca0',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0x10fE3E0261f67f3A000cae2E7D4Ad1b8df86Dca0#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '5months_BSC',
    //     vesting: 5,
    //     type: 'SINGLE',
    //     address: '0xCD96844d1Ca31D8535220A440498FD39Ac675283',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0xCD96844d1Ca31D8535220A440498FD39Ac675283#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '10months_BSC',
    //     vesting: 10,
    //     type: 'SINGLE',
    //     address: '0xB21D6c7748247FC343c62e11906854EdcD28ca8D',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0xB21D6c7748247FC343c62e11906854EdcD28ca8D#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
    // {
    //     chainId: 56,
    //     name: 'BTE',
    //     eventLabel: '12months_BSC',
    //     vesting: 12,
    //     type: 'SINGLE',
    //     address: '0xe2A2BAAc017cD97A2114237411D59eFb7c392BD2',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     rewardTokenAddress: '0x7faaf8d4c411218415d9d3f82d56214658349dbb',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://bscscan.com/address/0xe2A2BAAc017cD97A2114237411D59eFb7c392BD2#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x7faaf8d4c411218415d9d3f82d56214658349dbb'
    // },
]

export const ETH_FARM_CONTRCTS = [
    {
        chainId: 1,
        name: 'ETH-BTE',
        eventLabel: '1month_LP_ETH',
        vesting: 1,
        type: 'MULTIPLE',
        address: '0x2245D136B7696A233339dbC544aD09Dcf6BB5b3C',
        abi: BTE_LP_FARM_ABI,
        secondToken: 'ETH',
        tokenAddress: '0x130aE7A9Ed20cED337636B57B9562769D9A95EBb',
        rewardTokenAddress: '0x73b708e84837ffccde2933e3a1531fe61d5e80ef',
        tokenAbi: BTE_LP_TOKEN_ABI,
        contractLink: 'https://etherscan.io/address/0x2245D136B7696A233339dbC544aD09Dcf6BB5b3C#readContract',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x73b708e84837ffccde2933e3a1531fe61d5e80ef',
        addLiquidityLink: "https://app.uniswap.org/#/add/v2/ETH/0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef?chain=mainnet"
    },
    // {
    //     name: 'BTE',
    //     eventLabel: '3months_ETH',
    //     vesting: 3,
    //     type: 'SINGLE',
    //     address: '0x7D28629fD819ac9FdB25Fa7DbdE03D213C54D855',
    //     abi: BTE_SINGLE_FARM_ABI,
    //     tokenAddress: '0x73b708e84837ffccde2933e3a1531fe61d5e80ef',
    //     rewardTokenAddress: '0x73b708e84837ffccde2933e3a1531fe61d5e80ef',
    //     tokenAbi: BTE_SINGLE_TOKEN_ABI,
    //     contractLink: 'https://etherscan.io/address/0x7D28629fD819ac9FdB25Fa7DbdE03D213C54D855#code',
    //     buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x73b708e84837ffccde2933e3a1531fe61d5e80ef'
    // },
]

export const CRO_FARM_CONTRCTS = [
    {
        chainId: 25,
        name: 'CRO-BTE',
        eventLabel: '1month_LP_CRO',
        vesting: 1,
        type: 'MULTIPLE',
        address: '0xdc9f943FF17a5593Cb377F700DE31a7bab105894',
        abi: BTE_LP_FARM_ABI,
        secondToken: 'CRO',
        tokenAddress: '0x8efaf9f56c7aaea39823f3c3527b345cb2afe658',
        rewardTokenAddress: '0x3d632d9e1a60a0880dd45e61f279d919b5748377',
        tokenAbi: BTE_LP_TOKEN_ABI,
        contractLink: 'https://cronoscan.com/address/0xdc9f943FF17a5593Cb377F700DE31a7bab105894#readContract',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x73b708e84837ffccde2933e3a1531fe61d5e80ef',
        addLiquidityLink: "https://mm.finance/add/0x3D632d9e1a60a0880Dd45E61f279D919b5748377/CRO"
    },
]

export const BSCTEST_FARM_CONTRACTS = [
    {
        chainId: 97,
        name: 'BTE-BNB',
        vesting: 1,
        type: 'MULTIPLE',
        address: '0x0DCb3B9146C15a2924C3971601d1bA6cDA4E8423',
        abi: BTE_LP_FARM_ABI,
        secondToken: 'BNB',
        tokenAddress: '0x31611908113534Ad3282052C4Ba479EB3c1DDE72',
        rewardTokenAddress: '0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0',
        tokenAbi: BTE_LP_TOKEN_ABI,
        contractLink: 'https://bscscan.com/address/0x0DCb3B9146C15a2924C3971601d1bA6cDA4E8423#readContract',
        buyTokenLink: "https://pancakeswap.finance/add/BNB/0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0"
    },
    {
        chainId: 97,
        name: 'BTE',
        vesting: 3,
        type: 'SINGLE',
        address: '0x97C100f519015c2282f1351A563c825aa88D968C',
        abi: BTE_SINGLE_FARM_ABI,
        tokenAddress: '0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0',
        rewardTokenAddress: '0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0',
        tokenAbi: BTE_SINGLE_TOKEN_ABI,
        contractLink: 'https://testnet.bscscan.com/address/0x97C100f519015c2282f1351A563c825aa88D968C#readContract',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0'
    },
    {
        chainId: 97,
        name: 'BTE',
        vesting: 12,
        type: 'SINGLE',
        address: '0x3f41175C655c1dcC9a5F47733e7C37289132e8C8',
        abi: BTE_SINGLE_FARM_ABI,
        tokenAddress: '0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0',
        rewardTokenAddress: '0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0',
        tokenAbi: BTE_SINGLE_TOKEN_ABI,
        contractLink: 'https://testnet.bscscan.com/address/0x3f41175C655c1dcC9a5F47733e7C37289132e8C8#readContract',
        buyTokenLink: 'https://pancakeswap.finance/swap?outputCurrency=0x5f5D48B0F9c09e9ee8229bCBC0136a5687DDaeE0'
    },
]

export const BUY_BTE_CONTRACTS = {
    56: {
        address: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
        abi: BUY_BTE_ABI,
        tokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        tokenAbi: NEW_BTE_TOKEN_ABI,
        pathArray: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0x7c33D9c9685408645486497c708ab491402e0886']
    },
    1: {
        address: '0x7a250d5630b4cf539739df2c5dacb4c659f2488d',
        abi: BUY_BTE_ABI,
        tokenAddress: '0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef',
        tokenAbi: BTE_SINGLE_TOKEN_ABI,
        pathArray: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef']
    },
    25: {
        address: '0x145677fc4d9b8f19b5d56d1820c48e0443049a30',
        abi: BUY_BTE_ABI,
        tokenAddress: '0x3d632d9e1a60a0880dd45e61f279d919b5748377',
        tokenAbi: BTE_SINGLE_TOKEN_ABI,
        pathArray: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', '0x3d632d9e1a60a0880dd45e61f279d919b5748377']
    }
}

export const BTE_MIGRATION_CONTRACTS = {
    56: {
        address: '0x5edd6E753F0593670880511C0A509Fe70A6c5b23',
        abi: BTE_MIGRATION_CONTRACT_ABI,
        oldTokenAbi: BTE_SINGLE_TOKEN_ABI,
        newTokenAbi: NEW_BTE_TOKEN_ABI,
        oldTokenAddress: '0x7faAf8d4C411218415d9d3f82D56214658349dbb',
        newTokenAddress: '0x7c33D9c9685408645486497c708ab491402e0886',
        pathArray: ['0x7faAf8d4C411218415d9d3f82D56214658349dbb', '0x7c33D9c9685408645486497c708ab491402e0886']
    },
    1: {
        address: '0x5edd6E753F0593670880511C0A509Fe70A6c5b23',
        oldTokenAddress: '0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef',
        newTokenAddress: [],
        transferAddress: '0x9b9b56799DfB3687762DA6348b9243788e315643',
        oldTokenAbi: BTE_ETH_ABI,
        newTokenAbi: '',
        abi: BTE_MIGRATION_CONTRACT_ABI,
    },
    25: {
        address: '0x5edd6E753F0593670880511C0A509Fe70A6c5b23',
        oldTokenAddress: '0x3D632d9e1a60a0880Dd45E61f279D919b5748377',
        newTokenAddress: [],
        transferAddress: '0x9b9b56799DfB3687762DA6348b9243788e315643',
        oldTokenAbi: BTE_CRO_ABI,
        newTokenAbi: '',
        abi: BTE_MIGRATION_CONTRACT_ABI,
    },
}