import React from "react";
import { useWeb3React } from "@web3-react/core";

import BscIcon from "../../assets/images/BNBchain.svg";
import Button from "../Button";
import { handleNetworkSwitch } from "../../services/utils";

const SwitchNetwork = () => {
  const { connector, chainId } = useWeb3React();

  const onChange = async () => {
    await handleNetworkSwitch(connector, chainId, 56);
  }

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 h-screen w-screen backdrop-blur z-[100] font-poppins">
      <div className="flex w-full h-full">
        <div className="flex flex-col m-auto items-start px-4 md:px-6 py-4 md:py-5 max-w-96 mx-auto">
          <div className="flex justify-center w-full max-sm:w-full">
            <img src={BscIcon} alt="" className="w-14 h-14" />
          </div>
          <div className="flex flex-col items-center justify-center px-4 w-full mt-auto mb-5 self-center max-sm:order-2">
            <h1 className="text-[25px] font-medium m-auto">
              Switch Network to BNB Smart Chain
            </h1>
            <p
              className={`text-gray-light font-bold w-2/5 text-center`}
            >
            To use Betero, please switch to the BNB Smart Chain network. Check your wallet settings and select 'BNB Smart Chain' under Networks.
            </p>
            <div className="w-1/4 mt-5">
              <Button
                type={"contained"}
                compact
                className="flex items-center justify-center px-4 h-50px cursor-pointer w-10 mt-auto mb-5 self-center max-sm:order-2"
                onClick={onChange}
              >
                <span className={`font-bold`}>
                  Switch to BSC
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNetwork;
