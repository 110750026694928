import { default as BN } from "bn.js";
import intervalToDuration from "date-fns/intervalToDuration";
import { MONTH_SEC } from "../abis/constants";
import { ethers } from "ethers";
import { networkConnection, walletConnectConnection } from '../connectors';
import { toast } from 'react-toastify';
import { NETWORK_INFO } from '../abis/constants';

export const makeBNumber = (amount, decimal) => {
  const str = amount.toString();
  const isDecimal = str.includes(".");
  const decimalNumber = isDecimal ? str.split(".")[1].length : 0;
  let amountInt = parseInt(str.split(".").join(""));
  let decimalb = 10 ** (decimal - decimalNumber);
  if (decimalNumber > decimal) {
    amountInt = amountInt / 10 ** (decimalNumber - decimal);
    decimalb = 1;
  }

  const decimals = new BN(decimalb.toString());
  const bn = new BN(new BN(amountInt).mul(decimals));
  return bn;
};

export const parseBNumber = (amount, decimals) => {
  try {
    return parseFloat(ethers.utils.formatUnits(amount, decimals));
  } catch (e) {
    console.log(e);
  }
};

export const queryUrl = (url, params) => {
  const paths = [];
  for (const key of Object.keys(params)) {
    const atomStr = params[key] ? `${key}=${params[key]}` : `${key}`;
    paths.push(atomStr);
  }
  return `${url}?${paths.join("&")}`;
};

export const readableNumber = (number) => {
  if (number) {
    const str = String(number);
    if (str.includes(".")) {
      const str0 = str.split(".")[0];
      const str1 = str.split(".")[1];
      return str0.replace(/(.)(?=(\d{3})+$)/g, "$1,") + "." + str1;
    } else {
      return str.replace(/(.)(?=(\d{3})+$)/g, "$1,");
    }
  } else {
    return 0;
  }
};

export const parseDateTime = (timestamp) => {
  const dateTime = new Date(timestamp);
  const y = dateTime.getFullYear();
  const m = ("00" + (dateTime.getMonth() + 1)).slice(-2);
  const d = ("00" + dateTime.getDate()).slice(-2);
  const hh = ("00" + dateTime.getHours()).slice(-2);
  const mm = ("00" + dateTime.getMonth()).slice(-2);
  const ss = ("00" + dateTime.getSeconds()).slice(-2);
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
};

export const formatDate = (dateStr) => {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (!dateStr) return "";
  const dateTime = new Date(dateStr);
  const y = dateTime.getFullYear();
  const m = dateTime.getMonth();
  const d = dateTime.getDate();

  return `${monthName[m]}. ${d}, ${y}`;
};

export const calRemainDate = (endDate, locale) => {
  const diff = intervalToDuration({
    start: new Date(),
    end: new Date(endDate),
  });

  let result = [];
  if (diff.years !== 0) result.push(`${diff.years} years`);
  if (diff.months !== 0) result.push(`${diff.months} ${locale["months"]}`);
  if (diff.days !== 0) result.push(`${diff.days} ${locale["days"]}`);
  if (diff.years === 0 && diff.months === 0 && diff.days === 0) {
    return locale["less_than_1day"];
  }
  return `${locale["after"]} ${result.join(", ")}`;
};

export const calPastDate = (startDate, locale) => {
  const diff = intervalToDuration({
    start: new Date(startDate),
    end: new Date(),
  });

  let result = [];
  if (diff.years !== 0) result.push(`${diff.years} years`);
  if (diff.months !== 0) result.push(`${diff.months} ${locale["months"]}`);
  if (diff.days !== 0) {
    result.push(`${diff.days} ${locale["days"]}`);
  }
  if (diff.years === 0 && diff.months === 0 && diff.days === 0) {
    if (diff.hours !== 0) result.push(`${diff.hours} ${locale["hours"]}`);
    else result.push(`${diff.minutes} ${locale["minutes"]}`);
  }

  return result.length > 0 ? result.join(", ") : `0 ${locale["minutes"]}`;
};

export const getVestingFormat = (vesting, locale) => {
  if (!vesting) return "None";
  const vest = vesting / 1000; // in second
  if (vest >= MONTH_SEC)
    return `${Math.round(vest / MONTH_SEC)} ${locale["months"]}`;
  else if (vest >= 24 * 3600)
    return `${Math.round(vest / (24 * 3600))} ${locale["days"]}`;
  else if (vest >= 3600) return `${Math.round(vest / 3600)} ${locale["hours"]}`;
  else if (vest >= 60) return `${Math.round(vest / 60)} ${locale["minutes"]}`;
  else return `${vest} ${locale["seconds"]}`;
};

export const handleNetworkSwitch = async (connector, initialChain, chainId) => {
  try {
    if (
      connector === walletConnectConnection.connector ||
      connector === networkConnection.connector
    ) {
      await connector.activate(chainId);
    } else {
      const _info = NETWORK_INFO[chainId][0];
      const addChainParameter = {
        chainId: chainId,
        chainName: _info?.chainName,
        rpcUrls: _info?.rpcUrl,
        nativeCurrency: _info?.nativeCurrency,
        blockExplorerUrls: _info?.blockExplorerUrls,
      };
      await connector.activate(initialChain);
      await connector.activate(addChainParameter);
    }
  } catch (e) {
    console.log(e);
    if (e.message !== 'User rejected the request.')
      toast.error(
        "Failed to switch network. Please switch the network in your wallet’s settings."
      );
  }
};