import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";

import UpvoteIcon from "../../assets/images/upvote-icon.svg";
import DownvoteIcon from "../../assets/images/downvote-icon.svg";
import TickIcon from "../../assets/images/tick-icon.svg";
import { parseBNumber, readableNumber } from "../../services/utils";
import { CONTRACT_BTE_GOVERNANCE, DECIMALS } from "../../abis/constants";
import Spinner from '../Spinner';

export const Proposal = (props) => {
  const {account, provider} = useWeb3React();
  const {index} = props;
  const {locale} = useSelector((state) => state.common);
  const [bteVotingPower, setBTEVotingPower] = useState(0);
  const [noVoteUp, setNoVoteUp] = useState(0);
  const [noVoteDown, setNoVoteDown] = useState(0);
  const [proposalName, setProposalName] = useState("");
  const [voted, setVoted] = useState(false);
  const [proposalEnded, setProposalEnded] = useState(false);
  const [finalResult, setFinalResult] = useState(0);
  const [isUpvoting, setIsUpVoting] = useState(false);
  const [isDownvoting, setIsDownVoting] = useState(false);
  const upVotePercentage = useMemo(() => {
    if (noVoteUp + noVoteDown > 0) {
      return noVoteUp / (noVoteUp + noVoteDown);
    }
    return 0;
  }, [noVoteDown, noVoteUp]);
  const downVotePercentage = useMemo(() => {
    if (noVoteUp + noVoteDown > 0) {
      return noVoteDown / (noVoteUp + noVoteDown);
    }
    return 0;
  }, [noVoteDown, noVoteUp]);

  const getVoteInfo = useCallback(async () => {
    try {
      const governanceContract = new Contract(
        CONTRACT_BTE_GOVERNANCE.address,
        CONTRACT_BTE_GOVERNANCE.abi,
        provider
      );
      if (account) {
        const votingPower = await governanceContract.calculateVotingPower(account);
        const votingPowerBTE = parseBNumber(votingPower, DECIMALS);
        setBTEVotingPower(votingPowerBTE);
      }
      const proposalInfo = await governanceContract.proposals(index);
      const _noVoteUp = parseBNumber(proposalInfo.noVoteUp, DECIMALS);
      const _noVoteDown = parseBNumber(proposalInfo.noVoteDown, DECIMALS);
      setProposalEnded(new Date(proposalInfo.endTime * 1000) <= new Date());
      setFinalResult(Number(proposalInfo.finalResult));
      setProposalName(proposalInfo.name);
      setNoVoteUp(_noVoteUp);
      setNoVoteDown(_noVoteDown);
      if (account) {
        const _voted = await governanceContract.voteInfo(index, account);
        setVoted(_voted);
      }
    } catch (e) {
      console.log(e);
    }
  }, [account, index, provider]);

  useEffect(() => {
    getVoteInfo();
  }, [getVoteInfo]);

  const handleUpVote = async () => {
    if (bteVotingPower < 0.01) {
      toast.error(locale['low_bte_balance']);
      return;
    }
    setIsUpVoting(true);
    try {
      const governanceContract = new Contract(CONTRACT_BTE_GOVERNANCE.address, CONTRACT_BTE_GOVERNANCE.abi, provider.getSigner(account));
      const voteTx = await governanceContract.vote(3);
      await voteTx.wait();
      await getVoteInfo();
    } catch (e) {
      console.log(e);
    }
    setIsUpVoting(false);
  };

  const handleDownVote = async () => {
    if (bteVotingPower < 0.01) {
      toast.error(locale['low_bte_balance']);
      return;
    }
    setIsDownVoting(true);
    try {
      const governanceContract = new Contract(CONTRACT_BTE_GOVERNANCE.address, CONTRACT_BTE_GOVERNANCE.abi, provider.getSigner(account));
      const voteTx = await governanceContract.vote(1);
      await voteTx.wait();
      await getVoteInfo();
    } catch (e) {
      console.log(e);
    }
    setIsDownVoting(false);    
  };

  return (
    <div className="relative mb-4 px-4 md:px-8 py-3 md:py-5 bg-secondary rounded-xl">
      <div className="flex justify-between">
        <p className="text-15 text-green-primary font-medium">
          Proposal #{index}
        </p>
        {!proposalEnded ? (
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 bg-green-primary rounded-full"/>
            <p className="text-12 text-green-primary font-medium">{locale['active']}</p>
          </div>
        ) : finalResult === 0 ? (
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 bg-orange-primary"/>
            <p className="text-12 text-orange-primary font-medium">{locale['finalizing']}</p>
          </div>
        ) : (
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 bg-gray-light"/>
            <p className="text-12 text-gray-light font-medium">{locale['ended']}</p>
          </div>
        )}
      </div>
      <span className="mt-3 text-26 text-white font-medium">
        {proposalName}{" "}
        {proposalEnded && finalResult > 0 && (
          <>
            {"- "}
            {finalResult === 3 ? (
              <span className="text-green-primary">{locale['passed']}</span>
            ) : (
              <span className="text-pink-primary">{locale['not_passed']}</span>
            )}
          </>
        )}
      </span>
      <div className="mt-4 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="w-full md:w-2/3">
          <div className="h-3 bg-green-primary rounded-sm" style={{width: `${upVotePercentage * 100}%`}} />
          <p className="text-14 text-bold">
            {readableNumber(noVoteUp.toFixed(0))} BTE
          </p>
          <p className="text-12 text-normal opacity-50">{locale['upvotes']}</p>
        </div>
        {account && !proposalEnded && !voted && (
          <div className="mt-6 md:mt-0 flex justify-center">
            <button
              className="w-40 h-50px p-1 rounded-full border border-green-primary bg-green-overlay cursor-pointer flex items-center"
              disabled={isUpvoting}
              onClick={handleUpVote}
            >
              <img src={UpvoteIcon} alt="" className="w-38px h-38px" />
              <div className="w-full flex justify-center">
                {isUpvoting ? <Spinner /> : <span className="text-14 px-5 font-bold">{locale['upvote']}</span>}
              </div>
            </button>
          </div>
        )}
      </div>
      <div className="mt-6 flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="w-full md:w-2/3">
          <div className="h-3 bg-pink-primary rounded-sm" style={{width: `${downVotePercentage * 100}%`}} />
          <p className="text-14 text-bold">
            {readableNumber(noVoteDown.toFixed(0))} BTE
          </p>
          <p className="text-12 text-normal opacity-50">{locale['downvotes']}</p>
        </div>
        {account && !proposalEnded && !voted && (
          <div className="mt-6 md:mt-0 flex justify-center">
            <button
              className="w-40 h-50px p-1 rounded-full border border-pink-primary bg-pink-overlay cursor-pointer flex items-center"
              disabled={isDownvoting}
              onClick={handleDownVote}
            >
              <img src={DownvoteIcon} alt="" className="w-38px h-38px" />
              <div className="w-full flex justify-center">
                {isDownvoting ? <Spinner /> : <span className="text-14 px-5 font-bold">{locale['downvote']}</span>}
              </div>
            </button>
          </div>
        )}
      </div>
      {account && !proposalEnded && voted && (
        <button
          className="block md:absolute md:top-1/2 transform md:-translate-y-1/2 right-8 mt-6 md:mt-0 h-50px p-1 rounded-full border border-gray-primary bg-gray-overlay cursor-pointer flex items-center"
        >
          <div className="w-full flex justify-center px-5">
            <span className="text-14 pr-3 font-bold">{locale['voted']}</span>
            <img src={TickIcon} alt="" className="w-30px h-30px" />
          </div>
        </button>
      )}
      <div className="mt-4">
        {!proposalEnded ? (
          <>
            <p className="text-12 text-normal text-gray-light">
              {locale["proposal_up_downvote_desc"]}
            </p>
            <div>
              <span className="text-12 text-normal text-gray-light">
                {locale["learn_more_about_proposal"]}
                <a
                  href="https://medium.com/@Beterocoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-12 text-green-primary">
                    {locale["blog"]}
                  </span>
                </a>
              </span>
            </div>
          </>
        ) : finalResult === 0 ? (
          <p className="text-12 text-normal text-gray-light">{locale["results_are_finalizing"]}</p>
        ) : (
          <p className="text-12 text-normal text-gray-light">
            {locale["results_are_final"]}{" "}
            {finalResult === 3
              ? locale["proposal_has_passed"]
              : locale["proposal_has_not_passed"]}
          </p>
        )}
      </div>
    </div>
  );
};
