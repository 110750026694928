import { useState, useEffect } from 'react';

const useKyberTokenList = (chainId) => {
    const [tokens, setTokens] = useState([]);
    // loading set to true by default so that the kyberswap widget does not render until list is received
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const response = await fetch(`https://ks-setting.kyberswap.com/api/v1/tokens?page=1&pageSize=100&isWhitelisted=true&chainIds=${chainId}`);
                const data = await response.json();
                // console.log('tokens from hook:', data.data.tokens);
                // below slice removes the native network token from the list, because when adding
                // a custom token list to widget they do it for us by default (no duplicates then)
                const updatedTokens = data.data.tokens && data.data.tokens.length > 0 ? data.data.tokens.slice(1) : [];
                setTokens(updatedTokens || []);
            } catch (err) {
                setError(err.message);
                console.error("Failed to fetch tokens:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchTokens();
    }, [chainId]);

    return { tokens, loading, error };
};

export default useKyberTokenList;
