import Button from '../../components/Button';
import React from 'react';
import WarningImg from '../../assets/images/warning.png';

export const ErrorContent = () => {
  const onErrorReturn = () => {
    window.location.assign('/');
  };

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 h-screen w-screen backdrop-blur z-[100] font-poppins">
      <div className="flex w-full h-full">
        <div className="flex flex-col m-auto items-start px-4 md:px-6 py-4 md:py-5 max-w-96 mx-auto">
          <div className="flex justify-center w-full max-sm:w-full">
            <img src={WarningImg} alt="" className="w-14 h-14" />
          </div>
          <div className="flex flex-col items-center justify-center px-4 w-full mt-auto mb-5 self-center max-sm:order-2">
            <h1 className="text-[25px] font-medium m-auto">
              Oops! Something Went Wrong
            </h1>
            <p
              className={`text-gray-light font-bold w-2/5 text-center`}
            >
              We're sorry, but an unexpected error has occurred. Our team has been notified and is working hard to
              resolve this issue. Please try refreshing the page or come back later.
            </p>
            <div className="w-1/4 mt-5">
              <Button
                type={"contained"}
                compact
                className="flex items-center justify-center px-4 h-50px cursor-pointer w-10 mt-auto mb-5 self-center max-sm:order-2"
                onClick={onErrorReturn}
              >
                <span className={`font-bold`}>
                  Return to Home Page
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
