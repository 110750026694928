import React from 'react';
import { ErrorContent } from '../../pages/Error';

export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { didCatch: false, error: null, redirect: false };
    }

    static getDerivedStateFromError(error) {
        return { didCatch: true, error };
    }

    componentDidCatch(error) {
        this.setState({
            didCatch: true,
            error: error,
        });
        console.log({ error });
    }

    render() {
        if (this.state.didCatch) {
            return <ErrorContent />;
        }
        return this.props.children;
    }
}
