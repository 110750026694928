import React, { useEffect, useState, useRef } from 'react';

import { useWeb3React } from '@web3-react/core';

import { Widget } from "@kyberswap/widgets";

import WalletPopup from '../WalletPopup';
import Button from '../Button';
import '../../assets/css/global.scss';

import useKyberTokenList from '../../hooks/useKyberSwapTokenList';
import Spinner from '../Spinner';
import './kyberswap.css'

export function KyberSwapWidget(props) {
    const { openBuyPopup, setOpenPopup } = props;
    const { account, provider, chainId } = useWeb3React();
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [defaultTokenOut, setdefaultTokenOut] = useState(undefined);
    const [customTokenList, setCustomTokenList] = useState([]);

    // using hook to fetch other tokens from kyberswap to add on the CustomTokenList
    const { tokens, loading, error } = useKyberTokenList(chainId);

    // Create a ref to track the popup container
    const popupRef = useRef(null);

    const theme = React.useMemo(() => ({
        primary: '#1C1C1C',
        secondary: '#0F0F0F',
        dialog: '#313131',
        borderRadius: '20px',
        buttonRadius: '24px',
        stroke: '#505050',
        interactive: '#212121',
        accent: '#63C127',
        success: '#189470',
        warning: '#F1B02F',
        error: '#E93424',
        text: '#FFFFFF',
        subtext: '#A9A9A9',
        fontFamily: 'Poppins',
    }), []);

    const feeSetting = React.useMemo(() => ({
        //     If isInBps is set to true, the feeAmount value is treated as basis points.
        //     Basis points represent a hundredth of a percentage point, so a fee amount of 25 means 0.25% (25 / 10000).
        feeAmount: 25,
        feeReceiver: "0x5f695E11e3f6b0Ea6ad1141738B1635a24d6c990",
        chargeFeeBy: "currency_out",
        isInBps: true,
    }), []);
    // when enableRoute is set to true, allows the widget to show the best possible trading route across different liquidity pools or exchanges, potentially spanning multiple decentralized exchanges(DEXes) for the swap.
    // It ensures that the widget can optimize the swap by selecting the most efficient route to trade the tokens.
    const enableRoute = true;

    // when showDetail is set to true, the widget displays detailed swap information, such as the expected amount of tokens the user will receive, fees, and potential slippage.
    // This detail provides transparency about the swap process.
    const showDetail = true;

    // when showRate is set to true, the widget displays the exchange rate between the input and output tokens directly in the UI.
    // This helps users understand the current rate at which their input tokens will be swapped for the desired output tokens.
    const showRate = true;

    useEffect(() => {
        if (loading === false) {
            setCustomTokenList(prev => [
                ...tokens
            ]);
        }
    }, [tokens, chainId, loading]);

    useEffect(() => {
        if (customTokenList.length > 0 && !loading) {
            const bteToken = customTokenList.filter((item) => item.symbol && item.symbol === 'BTE')[0];
            bteToken['address'] = '0x7c33D9c9685408645486497c708ab491402e0886';
            setTimeout(() => {
                const tokenInteractieBtn = document.querySelectorAll('img');
                tokenInteractieBtn.forEach((el) => {
                    if (el.alt === 'tokenIn' || el.alt === 'tokenOut')
                        el.parentElement.classList.add('kyberswaptokenContainer');
                });
                setdefaultTokenOut(bteToken.address);
            }, 1500);
        }
    }, [customTokenList, loading]);

    // Add event listener to detect clicks outside the widget
    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopup(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.removeProperty("overflow");
        };
    }, [setOpenPopup]);

    useEffect(() => {
        if (openBuyPopup) document.body.style.overflow = "hidden";
    }, [openBuyPopup]);

    useEffect(() => {
        if (account === undefined) {
            setOpenWalletPopup(true);
        } else {
            setOpenWalletPopup(false);
        }

    }, [account, setOpenPopup]);

    return (
        <div className={"popup-container"}>


            <div ref={popupRef} className='KyberSwapWidget'>
                {!openWalletPopup && account !== undefined && (
                    loading ? (
                        <Spinner />
                    ) : error ? (
                        <Button className='h-50px'>Error loading tokens! Try refreshing the page and try again.</Button>
                    ) : !loading && customTokenList.length > 0 && !error && (

                        <Widget
                            client="Betero"
                            theme={theme}
                            tokenList={customTokenList}
                            provider={provider}
                            defaultTokenOut={defaultTokenOut}
                            feeSetting={
                                feeSetting.feeAmount && feeSetting.feeReceiver
                                    ? feeSetting
                                    : undefined
                            }
                            enableRoute={enableRoute}
                            showDetail={showDetail}
                            showRate={showRate}
                        />)
                )}
                {openWalletPopup && (
                    <WalletPopup
                        setOpenPopup={setOpenPopup}
                    />
                )}
            </div>

        </div>
    );
}

export default KyberSwapWidget;

