import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import BscIcon from '../../assets/images/BNBchain.svg';
import ChevronIcon from '../../assets/images/chevron-white.svg';
import EthIcon from '../../assets/images/eth-logo.svg';
import CroIcon from '../../assets/images/cro-network.svg';
import SwitchNetwork from '../SwitchNetwork';
import { handleNetworkSwitch } from '../../services/utils';

const SelectNetwork = () => {
  const { chainId, connector } = useWeb3React();
  const location = useLocation();
  const isMigration = location.pathname === '/migration';
  const [openMenu, setOpenMenu] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const networks = {
    56: { name: 'BSC', icon: BscIcon }
  };

  const migrationNetworks = {
    56: { name: 'BSC', icon: BscIcon },
    1: { name: 'ETH', icon: EthIcon },
    25: { name: 'CRO', icon: CroIcon }
  };

  const selectedNetwork = isMigration ? migrationNetworks : networks;

  return (
    <div className='relative my-5.5 font-montserrat'>
      <div
        className='border border-green-primary rounded-full flex items-center justify-center px-4 h-50px cursor-pointer'
        onClick={() => setOpenMenu(true)}
      >
        {chainId ? (
          <>
            <img src={selectedNetwork[chainId ?? 56]?.['icon']} alt="" className='w-6 h-6' />
            <p className='text-14 font-bold px-2'>{selectedNetwork[chainId ?? 56]?.['name']}</p>
          </>
        ) : (<p className='text-14 font-bold px-2'>Select network</p>)}
        <img src={ChevronIcon} alt="" className='w-5' />
      </div>
      {openMenu && (
        <>
          <div className='z-10 bg-gray-900 absolute top-14 left-0 rounded-lg shadow overflow-hidden z-30'>
            {Object.keys(selectedNetwork).map((net, index) => {
              return (
                <div
                  key={index}
                  className='flex items-center pl-4 pr-9 py-2 cursor-pointer hover:bg-green-overlay'
                  onClick={async () => {
                    await handleNetworkSwitch(connector, Number(net), Number(net)).then(() => {
                      setOpenPopup(false);
                    })
                  }}
                >
                  <img src={selectedNetwork[net]?.['icon']} alt="" className='w-6 h-6' />
                  <p className='text-14 px-2'>{selectedNetwork[net]?.['name']}</p>
                </div>
              )
            })}
          </div>
          <div className='overlay' onClick={() => setOpenMenu(false)} />
        </>
      )}
      {openPopup && (
        <SwitchNetwork />
      )}
    </div>
  )
}

export default SelectNetwork;
